<template>
  <div class="m-auto">
    <ul class="navbar-nav">
      <li class="nav-item px-2" v-for="link in links" :key="link.name">
        <router-link :to="link.route" class="nav-link">
          <span class="text-center w-100">{{ link.name }}</span>
        </router-link>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  computed: {
    links() {
      return [
        {
          name: 'Work',
          route: '/work',
        },
        {
          name: 'Reports',
          route: `/${this.orgId}/reports`,
        },
      ]
    },
    orgId() {
      return (
        this.$store.getters['organization/current']?.tablePartitionKey?.split(
          '#',
        )[1] ||
        this.$store.getters['profile/user']['custom:default_org']?.toLowerCase()
      )
    },
  },
}
</script>

<style scoped>
.router-link-active::after {
  content: '';
  left: 0;
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 1.6px;
  background-color: rgb(87, 87, 87);
}
.router-link-active span {
  color: rgb(87, 87, 87);
  font-weight: 600;
}
.nav-link {
  min-width: 7rem;
  font-weight: 400;
}
</style>
