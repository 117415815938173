<template>
  <Movable :offsetLeft="450" :offsetTop="60" v-if="isLoaded" class="data-modal">
    <div class="d-flex justify-content-between mb-2">
      <h6 class="mt-1">Apps data metadata</h6>
      <i @click="$emit('close')"
        ><BaseIcon name="xmark" size="15" color="white"
      /></i>
    </div>
    <div v-if="integrationsList.length > 0">
      <p>Your updates</p>
      <ul class="px-1">
        <li
          class="d-flex justify-content-between mb-2"
          v-for="(integration, index) in integrationsList"
          :key="index"
          @click="$emit('refresh')"
        >
          <span class="me-1 int-title"
            >{{ integration.title }}:
            {{ integration.classification === 'error' ? 'Error' : 'Active' }}
          </span>
          <span
            class="data-btn"
            @click="refreshIntegrationDataIncremental(integration)"
          >
            <BaseIcon name="sync" size="14" color="white" />
          </span>
          <span class="me-1 int-time"
            >{{ timeSince(integration.timestamp) }}
          </span>
        </li>
      </ul>
    </div>
  </Movable>
</template>

<script>
import { API, graphqlOperation } from 'aws-amplify'
import BaseIcon from '../../common/icons/BaseIcon.vue'
import Movable from '../../common/movable/Movable.vue'
import {
  deleteWildmetricRefreshIntegrationRecords,
  updateWildmetricCollectionRecords,
} from '../../../graphql/mutations'
import { onUpdateIntegrationStatus } from '../../../graphql/subscriptions'

export default {
  components: { Movable, BaseIcon },
  data() {
    return {
      integrationsList: [],
      isLoaded: false,
    }
  },
  methods: {
    // loads all the data needed for the meta data panel
    async init() {
      this.isLoaded = false
      this.$store.dispatch('integrations/getAll').then(() => {
        this.userIntegrations = this.$store.getters['integrations/all'].map(
          async integration => {
            const integrationNotifications = this.$store.getters[
              'notifications/nonActionableNotifications'
            ].filter(
              notification =>
                notification.dataSource === integration.tableSortKey,
            )[0]
            if (integrationNotifications)
              this.integrationsList.push(integrationNotifications)
          },
        )
      })
      this.isLoaded = true
      this.$emit('endLoad')
    },
    // Returns how much time since date formatted to be easily readable
    timeSince(pDate) {
      let date = pDate
      if (typeof date !== 'object') {
        date = new Date(date)
      }

      const seconds = Math.floor((new Date() - date) / 1000)
      let intervalType

      let interval = Math.floor(seconds / 31536000)
      if (interval >= 1) {
        intervalType = 'year'
      } else {
        interval = Math.floor(seconds / 2592000)
        if (interval >= 1) {
          intervalType = 'month'
        } else {
          interval = Math.floor(seconds / 86400)
          if (interval >= 1) {
            intervalType = 'day'
          } else {
            interval = Math.floor(seconds / 3600)
            if (interval >= 1) {
              intervalType = 'hour'
            } else {
              interval = Math.floor(seconds / 60)
              if (interval >= 1) {
                intervalType = 'minute'
              } else {
                interval = seconds
                intervalType = 'second'
              }
            }
          }
        }
      }

      if (interval > 1 || interval === 0) {
        intervalType += 's'
      }

      return `${interval} ${intervalType} ago`
    },
    async refreshIntegrationDataIncremental(integration) {
      const refreshSubscribe = API.graphql({
        query: onUpdateIntegrationStatus,
        variables: {
          tablePartitionKey:
            this.$store.getters['organization/current'].tablePartitionKey,
          integrationStatus: 'active',
        },
      }).subscribe({
        next: ({ value }) => {
          refreshSubscribe.unsubscribe()
          const response = value.data.onUpdateIntegrationStatus
          // TODO this should just change the status of the refreshed integration instead of loading the integrations
          if (response) {
            this.$store.dispatch('reports/filters/setupFilters')

            this.$store.dispatch('integrations/getAll')

            this.$store.dispatch('notifications/alerts/appendAlert', {
              type: 'success',
              msg: 'Integration updated successfully',
            })
          }
        },
        // TODO fix this
        // eslint-disable-next-line no-console
        error: error => console.log(error),
      })

      await API.graphql(
        graphqlOperation(updateWildmetricCollectionRecords, {
          input: {
            tableSortKey: integration.dataSource,
            tablePartitionKey:
              this.$store.getters['organization/current'].tablePartitionKey,
            integrationStatus: 'refreshing',
            organization: this.$store.getters[
              'organization/current'
            ].tablePartitionKey
              .split('#')
              .pop(''),
          },
        }),
      )

      await API.graphql(
        graphqlOperation(deleteWildmetricRefreshIntegrationRecords, {
          input: {
            integrationSource: integration.dataSource,
            organization: this.$store.getters[
              'organization/current'
            ].tablePartitionKey
              .split('#')
              .pop(''),
          },
        }),
      ).then(() => {
        this.$store.dispatch('notifications/alerts/appendAlert', {
          type: 'success',
          msg: `Starting refresh for ${integration.title}`,
        })
      })
    },
  },
  mounted() {
    this.integrationsList = []
    this.$emit('startLoad')
    this.init()
  },
}
</script>

<style lang="scss" scoped>
.data-modal {
  min-width: 510px; /* If width is less than 510px, the modal size is messed when reaches the right end of the window */
  min-height: 200px;
  border-radius: 5px;
  padding: 0.6rem 1rem;
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  user-select: none;
  font-size: 0.8rem;
}
.int-title {
  padding-right: 10rem;
}
.int-time {
  padding-left: 10px;
}
.data-btn {
  transition: transform 0.8s ease-in-out;
  -webkit-transition: -webkit-transform 0.8s ease-in-out;
}
.data-btn:hover {
  transform: rotate(360deg);
  -webkit-transform: rotate(360deg);
  cursor: pointer;
}
li {
  list-style-type: none;
}
</style>
