import Vue from 'vue'
import Router from 'vue-router'
import store from './store'
import {
  authUser,
  setupUser,
  setupOrganizations,
  setTitle,
} from './helpers/routerHelper'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      redirect: { name: 'work' }, // go to myWork if the user is authenticated.
    },
    {
      path: '/authorize',
      name: 'authorize',
      component: () => import('./views/Authorize.vue'),
      meta: {
        title: 'Authorize',
        requiresAuth: false,
      },
      beforeEnter: (to, from, next) => {
        if (store.getters.loggedInUser) {
          return next('/reports')
        }
        return next()
      },
    },
    {
      path: '/onboard',
      name: 'onboard',
      component: () => import('./views/Onboard.vue'),
      meta: {
        title: 'onboard',
        requiresAuth: true,
      },
      beforeEnter: (to, from, next) => {
        const orgs = store.getters['organization/joined'].filter(
          org => org.status !== 'disabled',
        )
        if (orgs.length > 0) {
          return next('/reports')
        }
        return next()
      },
    },
    {
      path: '/subscription-plan',
      name: 'subscription-plan',
      component: () => import('./views/Subscriptions.vue'),
      meta: {
        title: 'subscription-plan',
        requiresAuth: true,
      },
    },
    {
      path: '/work',
      name: 'work',
      component: () => import('./views/MyWork.vue'),
      meta: {
        title: 'work',
        requiresAuth: true,
      },
      beforeEnter(to, from, next) {
        store.dispatch('mywork/applyFilters')
        return next()
      },
    },
    {
      path: '/integrations',
      name: 'integrations',
      component: () => import('./views/integrations/Integrations.vue'),
      meta: {
        title: 'Integrations',
        requiresAuth: true,
      },
    },
    {
      path: '/:orgId/integrations/:sortKey',
      name: 'integrationView',
      props: true,
      component: () => import('./views/integrations/IntegrationView.vue'),
      meta: {
        title: 'Integration Page',
        requiresAuth: true,
      },
      beforeEnter: (to, from, next) => {
        const { sortKey } = to.params
        store.commit('integrations/setCurrent', {
          tableSortKey: sortKey,
        })
        next()
      },
    },
    {
      path: '/:orgId/groups/new',
      name: 'newGroupView',
      props: true,
      component: () => import('./views/groups/NewGroupView.vue'),
      meta: {
        title: 'New Group Page',
        requiresAuth: true,
      },
    },
    {
      path: '/:orgId/groups/:sortKey',
      name: 'groupView',
      props: true,
      component: () => import('./views/groups/GroupView.vue'),
      meta: {
        title: 'Group Page',
        requiresAuth: true,
      },
    },
    {
      path: '/notifications',
      name: 'notifications',
      component: () => import('./views/Notifications.vue'),
      meta: {
        title: 'Notifications',
        requiresAuth: true,
      },
      beforeEnter(to, from, next) {
        store.commit('loading')
        return next()
      },
    },
    {
      path: '/profile',
      name: 'profile',
      component: () => import('./views/Profile.vue'),
      meta: {
        title: 'Profile',
        requiresAuth: true,
      },
    },
    {
      path: '/:orgId/reports',
      name: 'reports',
      component: () => import('./views/Reports.vue'),
      meta: {
        title: 'Reports',
        requiresAuth: true,
      },
    },
    {
      path: '/:orgId/shared',
      name: 'shared',
      component: () => import('./views/Shared.vue'),
      meta: {
        title: 'Reports',
        requiresAuth: false,
      },
    },
    {
      path: '/billing',
      name: 'billing',
      component: () => import('./views/Billing.vue'),
      meta: {
        title: 'Billing',
        requiresAuth: true,
      },
    },
    {
      path: '/subscriptions',
      name: 'subscriptions',
      component: () => import('./views/SubscriptionDetails.vue'),
      meta: {
        title: 'Subscription',
        requiresAuth: true,
      },
    },
    {
      path: '/subscriptions/invoices/:id',
      name: 'invoiceInfo',
      props: true,
      component: () => import('./components/organization/Invoice.vue'),
      meta: {
        title: 'Invoice Info',
        requiresAuth: true,
      },
    },
    {
      path: '/plans',
      name: 'plans',
      component: () => import('./views/Subscriptions.vue'),
      meta: {
        title: 'plans',
        requiresAuth: true,
      },
    },
    {
      path: '/filters',
      name: 'filters',
      component: () => import('./views/AllFilters.vue'),
      meta: {
        title: 'Filters',
        requiresAuth: true,
      },
    },
    {
      path: '/integrations/add',
      name: 'addIntegrations',
      component: () => import('./views/integrations/AddIntegration.vue'),
      meta: {
        title: 'AddIntegration',
        requiresAuth: true,
      },
    },
    {
      path: '/integrations/jira/authorize',
      name: 'authorizeJira',
      meta: {
        title: 'AuthorizeJira',
        requiresAuth: true,
      },

      async beforeEnter() {
        // Encode the userSub, in the BE there will be a verification
        const userSub = new TextEncoder('utf-8').encode(
          store.getters['profile/user'].sub,
        )
        const hashBytes = await window.crypto.subtle.digest('SHA-256', userSub)

        function buf2hex(buffer) {
          return Array.prototype.map
            .call(new Uint8Array(buffer), x => `00${x.toString(16)}`.slice(-2))
            .join('')
        }

        const state = buf2hex(hashBytes)

        // VUE_APP_JIRA_ID should be different for each environment (prod, staging, localhost)
        const redirectUri = encodeURIComponent(
          `${window.location.origin}/integrations/new?type=Jira&org=${
            store.getters['organization/current'].tablePartitionKey.split(
              '#',
            )[1]
          }`,
        )
        const href = `https://auth.atlassian.com/authorize?audience=api.atlassian.com&client_id=${process.env.VUE_APP_JIRA_ID}&scope=offline_access%20read%3Agroup%3Ajira%20read%3Aissue%3Ajira%20read%3Aissue-meta%3Ajira%20read%3Aissue-worklog%3Ajira%20read%3Aissue-details%3Ajira%20read%3Aissue-worklog.property%3Ajira%20read%3Auser%3Ajira%20read%3Aproject%3Ajira%20read%3Aissue%3Ajira-software%20read%3Acomment%3Ajira%20read%3Acomment.property%3Ajira%20read%3Aissue-type%3Ajira%20read%3Aissue-type.property%3Ajira%20read%3Aissue-security-level%3Ajira%20read%3Aissue.vote%3Ajira%20read%3Aissue.changelog%3Ajira%20read%3Aavatar%3Ajira%20read%3Astatus%3Ajira%20read%3Afield-configuration%3Ajira%20read%3Aaudit-log%3Ajira%20read%3Aproject-role%3Ajira%20read%3Aapplication-role%3Ajira%20read%3Ajira-work&redirect_uri=${redirectUri}&state=${state}&response_type=code&prompt=consent`
        window.location.href = href
      },
    },
    {
      path: '/integrations/new',
      name: 'newIntegration',
      props: true,
      component: () => import('./views/integrations/NewIntegrationView.vue'),
      meta: {
        title: 'NewIntegrationForm',
        requiresAuth: true,
      },
      async beforeEnter(to, from, next) {
        const orgId = to.query.org
        const newTo = to
        newTo.params.orgId = orgId
        let hasError = false
        if (to.query.error !== undefined) {
          const error = to.query.error_description || to.query.error
          store.dispatch('notifications/alerts/appendAlert', {
            type: 'error',
            msg: `Error authorizing Jira application: ${error}`,
          })
          hasError = true
        }
        if (orgId !== undefined) {
          await store.dispatch('organization/joined').then(() => {
            const orgs = store.getters['organization/joined'].filter(
              org => org.status !== 'disabled',
            )
            store.commit(
              'organization/setCurrent',
              orgs.filter(org => {
                return org.tablePartitionKey === `organization#${orgId}`
              })[0],
            )
            if (hasError) return next('/integrations')
            return next()
          })
        }

        return next()
      },
    },
    {
      path: '/federated',
      name: 'federated',
      component: () => import('./views/Federated.vue'),
      meta: {
        title: 'Federated',
        requiresAuth: false,
      },
    },
    {
      path: '/organizations',
      name: 'organizations',
      component: () => import('./views/organization/OrganizationList.vue'),
      meta: {
        title: 'Create or join Organization',
        requiresAuth: true,
      },
    },
    {
      path: '/organization/:orgId',
      name: 'vieworganization',
      props: true,
      component: () => import('./views/organization/ViewOrganization.vue'),
      meta: {
        title: 'View Organization',
        requiresAuth: true,
      },
    },
    {
      path: '/organization/:orgId/:userSub',
      name: 'organizationUserPage',
      props: true,
      component: () => import('./components/organization/Person.vue'),
      meta: {
        title: 'Organization User Page',
        requiresAuth: true,
      },
    },
    {
      path: '/*',
      redirect: { name: 'reports' },
    },
  ],
})

router.beforeEach(async (to, from, next) => {
  // Sets the document title
  setTitle(to.meta.title)

  // Authenticate user and check if auth is required and get user data
  const { data, isLogged } = await authUser(to)
  if (!isLogged) return next(data)

  // Setting up all the needed user data
  await setupUser(data)
  // Clear all loaders
  if (from.name !== to.name) store.dispatch('loadingManager/clear')

  // Get subscription plans if not in store
  if (!store.getters['subscriptions/plans']) {
    store.dispatch('subscriptions/getPlans')
  }

  // Setting up all the organization and subscription data
  const orgResponseRoute = await setupOrganizations(to, from)
  // Handling the response from the setup and returning route
  if (orgResponseRoute) {
    if (from.name === orgResponseRoute || to.name === orgResponseRoute)
      return next()
    return next(orgResponseRoute)
  }

  if (isLogged) {
    return next()
  }
  // Default return
  return next('/authorize')
})

export default router
